import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { ComponentsMountPoints } from './components/Components';

const lazy_elements = [];

for (let i = 0; i < ComponentsMountPoints.length; ++i) {
    let component = ComponentsMountPoints[i];
    let ReactComponent = component.component;
	let element = document.getElementById(component.placeholder_id);
	if (!element) {
		continue;
	}
    if (element.className.indexOf('lazy') === -1) {
        ReactDOM.render(<ReactComponent />, element);
    } else {
		lazy_elements.push({ node: element, ReactComponent });
	}
}

if (lazy_elements.length > 0) {
	let tick = setInterval(() => {
		if (lazy_elements.length === 0) {
			clearInterval(tick);
		}
		for (let i = 0; i < lazy_elements.length; ++i) {
			const element = lazy_elements[i];
			if (element.node.className.indexOf('load-me') !== -1) {
				lazy_elements.splice(i, 1);
				i = 0;
				const ReactComponent = element.ReactComponent;
				const node = element.node;
				ReactDOM.render(<ReactComponent />, node);
			}
		}
	}, 150);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
