import React, { lazy, Suspense } from 'react';
const NewsList = lazy(() => import('../../components/SanicamCT/InformaList'));
const InformaSingleNews = lazy(() => import('../../components/SanicamCT/InformaSingle'));
const ConvenzioniList = lazy(() => import('../../components/SanicamCT/ConvenzioniList'));
const ModuliList = lazy(() => import('../../components/SanicamCT/ModuliList'));
const TecnologieList = lazy(() => import('../../components/SanicamCT/TecnologieList'));
const TecnologieSingle = lazy(() => import('../../components/SanicamCT/TecnologieSingle'));
const Popup = lazy(() => import('../../components/SanicamCT/Popup'));

const withSuspense = Component => props => (
	<Suspense fallback={<div className='react-loading'></div>}>
		<Component {...props}></Component>
	</Suspense>
);


export const ComponentsConfig = {Components: [
    {
        placeholder_id: "sanicam-informa-ph",
        component: withSuspense(NewsList)
    },
    {
        placeholder_id: "sanicam-informa-single",
        component: withSuspense(InformaSingleNews)
    },
    {
        placeholder_id: "sanicam-convenzioni-ph",
        component: withSuspense(ConvenzioniList)
    },
    {
        placeholder_id: "sanicam-moduli-ph",
        component: withSuspense(ModuliList)
    },
    {
        placeholder_id: "sanicam-tecnologie-ph",
        component: withSuspense(TecnologieList)
    },
    {
        placeholder_id: "sanicam-tecnologie-single",
        component: withSuspense(TecnologieSingle)
    },
    {
        placeholder_id: "sanicam-popup",
        component: withSuspense(Popup)
    }
]};
